<template>
  <el-container class="container">
    <el-container class="main-container">
      <el-header class="header" height="2.70833vw">
        <div class="header-left">
          <div class="header-title">
            <div class="header-title-icon" />
            个人资料
          </div>
        </div>
      </el-header>
      <el-main>
        <!-- 个人信息 -->
        <div class="accountInfo">
          <!-- 头像 -->
          <div class="accountInfo-item avatar">
            <div class="accountInfo-item-label">头像：</div>
            <div class="accountInfo-item-right">
              <!-- 头像 -->
              <div
                class="accountInfo-avatar"
                @mouseenter="avatarCoverState = true"
                @mouseleave="avatarCoverState = false"
              >
                <!-- 头像 -->
                <img
                  class="accountInfo-avatar-img"
                  :src="accountInfo.avatar"
                  alt=""
                />
                <!-- 遮罩 -->
                <el-upload
                  :action="action"
                  :before-upload="beforeUploadFunction"
                  :on-success="handleUploadSuccess"
                >
                  <div
                    class="accountInfo-avatar-cover"
                    v-show="avatarCoverState"
                  >
                    <img src="~a/workbench/edit-white.png" alt="" />
                  </div>
                </el-upload>
              </div>
              <!-- 头像格式 -->
              <p class="accountInfo-avatar-tip">
                支持2M以内，JPG、JPEG和PNG格式
              </p>
            </div>
          </div>
          <!-- 昵称 -->
          <div class="accountInfo-item">
            <div class="accountInfo-item-label">昵称：</div>
            <div class="accountInfo-item-right">
              <template v-if="!nicknameEditState">
                <p>{{ accountInfo.nickname }}</p>
                <button
                  class="editBtn"
                  @click="
                    (nicknameEditState = true),
                      (userData.nickname = accountInfo.nickname)
                  "
                >
                  <i class="icon icon-user-edit" />
                </button>
              </template>
              <template v-else>
                <el-input
                  type="text"
                  v-model="userData.nickname"
                  maxlength="20"
                />
                <button class="btn">
                  <img src="~a/workbench/confirm.png" alt="" />
                </button>
                <button
                  class="btn"
                  @click="(nicknameEditState = false), (userData.nickname = '')"
                >
                  <img src="~a/workbench/cancel.png" alt="" />
                </button>
              </template>
            </div>
          </div>
          <!-- ID -->
          <div class="accountInfo-item">
            <div class="accountInfo-item-label">ID号：</div>
            <div class="accountInfo-item-right">
              <p>{{ accountInfo.id }}</p>
            </div>
          </div>
          <!-- 手机号 -->
          <div class="accountInfo-item">
            <div class="accountInfo-item-label">手机号：</div>
            <div class="accountInfo-item-right">
              <template v-if="!phoneEditState">
                <p>{{ accountInfo.phone }}</p>
                <button
                  class="editBtn"
                  @click="
                    (phoneEditState = true),
                      (userData.phone = accountInfo.phone)
                  "
                >
                  <i class="icon icon-user-edit" />
                </button>
              </template>
              <template v-else>
                <el-input type="text" v-model="userData.phone" maxlength="11" />
                <button class="btn">
                  <img src="~a/workbench/confirm.png" alt="" />
                </button>
                <button
                  class="btn"
                  @click="(phoneEditState = false), (userData.phone = '')"
                >
                  <img src="~a/workbench/cancel.png" alt="" />
                </button>
              </template>
            </div>
          </div>
          <!-- 登录密码 -->
          <div class="accountInfo-item">
            <div class="accountInfo-item-label">登录密码：</div>
            <div class="accountInfo-item-right">
              <template v-if="!passwordEditState">
                <p>***********</p>
                <button class="editBtn" @click="passwordEditState = true">
                  <i class="icon icon-user-edit" />
                </button>
              </template>
              <template v-else>
                <el-input type="password" v-model="userData.password" />
                <button class="btn">
                  <img src="~a/workbench/confirm.png" alt="" />
                </button>
                <button
                  class="btn"
                  @click="(passwordEditState = false), (userData.password = '')"
                >
                  <img src="~a/workbench/cancel.png" alt="" />
                </button>
              </template>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <!-- 右侧升级信息 -->
    <el-aside class="aside" width="36.97917vw">
      <el-container class="aside-top">
        <el-header class="header" height="2.70833vw">
          <div class="header-title">
            <div class="header-title-icon" />
            空间容量
          </div>
          <!-- <div class="header-btn">空间容量</div> -->
        </el-header>
        <el-main class="mian">
          <!-- 已使用 -->
          <div class="mian-title">
            <span
              >已使用：<span style="color: rgba(254, 163, 19, 1)"
                >{{ capacityData.num1 }}M</span
              >/{{ capacityData.num2 }}M<span style="font-size: 0.9em"
                >（{{ capacityData.pct }}%）</span
              ></span
            >
            <router-link to="/serviceUpgrade"
              ><span style="color: #507cff">扩容</span></router-link
            >
          </div>
          <!-- 进度条 -->
          <el-slider
            class="mian-slider"
            v-model="capacityData.pct"
            :format-tooltip="formatTooltip"
            disabled
          ></el-slider>
          <!-- 表 -->
          <div class="mian-table">
            <div class="mian-table-column column1">
              <h1>容量构成方式</h1>
              <p>长期容量</p>
              <p>短期容量</p>
            </div>
            <div class="mian-table-column column2">
              <h1>容量大小</h1>
              <p>{{ capacityData.num3 }}M</p>
              <p>{{ capacityData.num4 }}M</p>
            </div>
            <div class="mian-table-column column3">
              <h1>有效期</h1>
              <p>{{ capacityData.time1 }}</p>
              <p>{{ capacityData.time2 }}</p>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="aside-bottom">
        <el-header class="header" height="2.70833vw">
          <div class="header-title">
            <div class="header-title-icon" />
            认证信息
          </div>
          <!-- <div class="header-btn">认证信息</div> -->
        </el-header>
        <el-main class="mian">
          <div class="mian-column">
            <span>个人认证</span>
            <span style="width: 35vw">神州龙</span>
            <span class="mian-column-click" @click="handleGrrz">修改</span>
          </div>
          <div class="mian-column">
            <span>企业认证</span>
            <span style="width: 35vw; color: #999999">未认证</span>
            <span class="mian-column-click" @click="handleAuthen"
              >认证企业</span
            >
          </div>
        </el-main>
      </el-container>
    </el-aside>
  </el-container>
</template>

<script>
/* eslint-disable */
export default {
  name: 'AccountInfo',
  data() {
    return {
      accountInfo: '',
      avatarCoverState: false,
      nicknameEditState: false,
      phoneEditState: false,
      passwordEditState: false,
      capacityData: {
        pct: 20,
        num1: '100',
        num2: '500',
        time1: '长期有效',
        time2: '2022/7/8',
        num3: '50',
        num4: '50'
      },
      userData: {
        avatar: '',
        nickname: '',
        phone: '',
        password: ''
      },
      action: '/uploadIp/los/upload/batch/openFile' //上传地址
    };
  },
  created() {
    this.accountInfo = {
      avatar: require('a/workbench/avatar.png'),
      nickname: '15326548411',
      id: 123456,
      phone: 15326548411,
      password: '************'
    };
  },
  methods: {
    //上传文件大小和格式校验
    beforeUploadFunction(file) {
      const _name = file.name;
      const _index = _name.lastIndexOf('.');
      const _imgType = _name.substring(_index);
      if (
        _imgType == '.jpg' ||
        _imgType == '.jpeg' ||
        _imgType == '.png' ||
        _imgType == '.PNG'
      ) {
      } else {
        this.$message.error(`请选择2M以内，JPG、JPEG和PNG格式图片`);
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    // 上传成功
    handleUploadSuccess(data) {
      if (data.status === 200) {
        this.accountInfo.avatar = '/fileIp' + data.data[0].fileUrl;
        localStorage.userImg = '/fileIp' + data.data[0].fileUrl;
      }
    },
    // 容量条气泡
    formatTooltip(val) {
      return val + '%';
    },
    // 认证
    handleAuthen() {
      this.$store.commit(
        'userData/setEnterpriseCertificationDialogVisible',
        true
      );
    },
    // 认证
    handleGrrz() {
      this.$store.commit('userData/setPersonalCertificateDialogVisible', true);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  border-radius: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  .main-container {
    border-radius: 10px;
    padding: 20px 28px;
    background-color: #fff;
  }
  .aside {
    margin-left: 20px;
    &-top,
    &-bottom {
      box-sizing: border-box;
      padding: 20px 28px;
      border-radius: 10px;
      background-color: #fff;
    }
    &-top {
      height: 368px;
      margin-bottom: 40px;
      .mian {
        margin: 0 30px;
        &-title {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
        // 容量条
        /deep/.el-slider {
          margin-top: 17px;
          cursor: default;
          &__runway {
            width: 100%;
            height: 12px;
            border-radius: 6px;
            background-color: #eaefff;
            position: relative;
            cursor: pointer;
            vertical-align: middle;
            cursor: default;
          }
          &__runway.disabled .el-slider__bar {
            background-color: #507cff;
            height: 12px;
            border-radius: 6px;
            cursor: default;
          }
          &__button {
            width: 16px;
            height: 16px;
            background: #ffffff;
            border: 1px solid #507cff;
            border-radius: 50%;
            margin-top: 6px;
            cursor: default;
            &-wrapper {
              cursor: default;
              z-index: 0;
            }
          }
        }
        // 表
        &-table {
          margin-top: 17px;
          display: flex;
          justify-content: space-between;
          &-column {
            width: 200px;
            h1 {
              height: 45px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              border-bottom: 1px solid #eeeeee;
            }
            p {
              margin-top: 15px;
              margin-bottom: 30px;
              height: 16px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 30px;
              text-align: center;
            }
          }
          .column1 {
            p {
              width: 108px;
            }
          }
          .column2 {
            text-align: center;
            width: 800px;
          }
          .column3 {
            text-align: center;
          }
        }
      }
    }
    &-bottom {
      height: 288px;
      .mian {
        margin: 0 30px;
        &-column {
          border-bottom: 1px solid #eeeeee;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          span {
            text-align: center;
            width: 200px;
            height: 78px;
            line-height: 78px;
          }
          &-click {
            line-height: 30px;
            font-size: 16px;
            cursor: pointer;
            color: #507cff;
          }
        }
      }
    }
  }
}

// 个人资料
.accountInfo {
  padding: 0 28px;
  &-item {
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 200px;
    border-bottom: 1px solid #eeeeee;
    color: #333333;
    font-size: 20px;
    &.avatar {
      align-items: flex-start;
      height: initial;
      padding: 36px 0 36px 200px;
      .accountInfo-item-right {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-label {
      width: 100px;
      margin-right: 20px;
      line-height: 85px;
      text-align: right;
      white-space: nowrap;
    }
    &-right {
      display: flex;
      align-items: center;
      .btn {
        width: 40px !important;
        height: 30px;
        margin: 0 37px 0 -25px;
        text-align: center;
        line-height: 30px;
        background: #507cff;
        border-radius: 4px;
        font-size: 0;
        img {
          margin-top: 6px;
          width: 18px;
          height: 18px;
        }
      }
      /deep/ .el-input {
        margin-right: 20px;
      }
    }
  }
  // 头像
  &-avatar {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 26px;
    border-radius: 50%;
    border: 1px solid rgba(80, 124, 255, 0.5);
    background-color: rgba(80, 124, 255, 0.1);
    overflow: hidden;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-cover {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &-tip {
      color: #666666;
      font-size: 16px;
    }
  }
}
/deep/.el-upload-list__item-name {
  width: 0px;
}
/deep/.el-input__inner {
  width: 212px;
}
/deep/.el-slider__runway {
  margin: 16px 0;
}
</style>
